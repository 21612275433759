import React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { layout } from './video-listing.module.scss';

import { IQueryAllResult } from '../models/query-all-result.model';
import { ISitePageContext } from '../models/site-page.model';
import { IVideo } from '../models/video.model';
import { IPage } from '../models/page.model';
import { IBreadcrumbsProps } from '../models/breadcrumbs.model';

import { getNodes } from '../utils/get-nodes';

import MainLayout from '../layouts/main-layout';
import Listing from '../components/organisms/listing';
import VideoCard from '../components/molecules/video-card';
import BlockHeader from '../components/molecules/block-header';

interface IVideoListing {
    readonly data: {
        allStrapiVideo: IQueryAllResult<IVideo>;
        page: IPage | null;
        pageBreadcrumbs: {
            breadcrumbs: IBreadcrumbsProps[];
        };
    };
    readonly pageContext: ISitePageContext;
}

const VideoListingPage: React.FC<IVideoListing> = ({ data, pageContext }: IVideoListing) => {
    const { allStrapiVideo, page } = data;
    const { paginationKeys } = pageContext;
    const { t, pageKey } = useI18next();

    const videos = getNodes(allStrapiVideo);
    const activeIndex = paginationKeys.indexOf(pageKey);

    return (
        <MainLayout
            className={layout}
            page={page}
            showRecipesSlider={false}
            includeHeaderPadding={true}
            showLocalizationBar={true}
            breadcrumbs={data.pageBreadcrumbs.breadcrumbs}
        >
            <BlockHeader
                titleText={t('video.listing.title')}
                subtitleText={t('video.listing.subtitle')}
            />
            <Listing
                columns={3}
                ItemComponent={VideoCard}
                data={videos}
                paginationConfig={{
                    links: paginationKeys.map((key) => {
                        return { to: key };
                    }),
                    activeIndex: activeIndex,
                }}
                itemIdKey="videoId"
            />
        </MainLayout>
    );
};

export const query = graphql`
    query ($language: String!, $videoId: [Int], $site: String!, $path: String) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        pageBreadcrumbs: sitePage(path: { eq: $path }) {
            breadcrumbs {
                label
                slug
            }
        }

        allStrapiVideo(
            filter: { videoId: { in: $videoId }, locale: { eq: $language } }
            sort: { fields: published_at, order: DESC }
        ) {
            edges {
                node {
                    ...baseVideoFields
                    slug
                }
            }
        }
        
        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            meta {
                ...pageMetaFields
            }
        }
    }
`;

export default VideoListingPage;
