import React from 'react';

import { header, subtitle, title, mobileHidden, uppercase } from './block-header.module.scss';

import Title from '../atoms/title';

interface IListingHeader {
    className?: string;
    titleText: string;
    subtitleText?: string;
    mobileHideSubtitle?: boolean;
    subtitleUppercase?: boolean;
}

const BlockHeader: React.FC<IListingHeader> = ({
    className = '',
    titleText,
    subtitleText,
    mobileHideSubtitle = true,
    subtitleUppercase = true,
}) => {
    return (
        <div className={`${header} ${className}`}>
            <Title isUppercase={true} className={title}>
                {titleText}
            </Title>
            {subtitle && (
                <span
                    className={`${subtitle} ${mobileHideSubtitle ? mobileHidden : ''} ${
                        subtitleUppercase ? uppercase : ''
                    }`}
                >
                    {subtitleText}
                </span>
            )}
        </div>
    );
};

export default BlockHeader;
